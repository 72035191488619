import { SearchProductsHitsToCarousel } from "@components/commerce/product/product-carousel/SearchProductHitsToCarousel";
import type { IModuleCustomProductCarousel } from "@contentful-api/types/contentful";
import { AnalyticsListTypes } from "@lib/analytics/analyticsTypes";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";
import { GetLiveUpdates } from "@lib/contentful/modules-renderer/ModulesRenderer";
import { useRouter } from "next/router";
import type { FC } from "react";

interface ICustomProductCarouselProps extends IModuleRenderedProps<IModuleCustomProductCarousel> {}

export const CustomProductCarousel: FC<ICustomProductCarouselProps> = ({
    module,
    authorizationInvitation,
}) => {
    const { isPreview } = useRouter();
    const updatedData = isPreview
        ? (GetLiveUpdates(module) as IModuleCustomProductCarousel)
        : module;
    const { header, products, assistiveLabel, extraLink, extraLinkLabel } =
        updatedData?.fields || {};
    const moduleId = updatedData?.sys?.id;

    if (!products) return;

    return (
        <SearchProductsHitsToCarousel
            products={products}
            headline={header}
            assistiveMessage={assistiveLabel}
            sortingOrder={products}
            carouselType={AnalyticsListTypes.custom}
            authorizationInvitation={authorizationInvitation}
            extraLink={extraLink}
            extraLinkLabel={extraLinkLabel}
            id={moduleId}
        />
    );
};

export default CustomProductCarousel;
