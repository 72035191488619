import { isRobotNoFollow, isRobotNoIndex } from "@components/seo/utils";
import type { ISeoMetadata } from "@contentful-api/types/contentful";
import { getCanonicalUrl } from "@lib/utils/getCanonicalUrl";
import type { NextSeoProps } from "next-seo";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";

interface PageSeoProps extends NextSeoProps {
    seoModule: ISeoMetadata;
    pathName: string;
}

export const PageSeo = ({ seoModule, pathName }: PageSeoProps) => {
    const { locale, defaultLocale } = useRouter();
    if (!seoModule) {
        return null;
    }

    const {
        fields: {
            title,
            canonical,
            robotIndex,
            description,
            robotFollow,
            openGraphImage: { fields: openGraphImage } = {},
            openGraphTitle,
            openGraphMetaDescription,
        } = {},
    } = seoModule;

    return (
        <NextSeo
            title={title}
            description={description}
            openGraph={{
                type: "website",
                title: openGraphTitle ?? title,
                ...(openGraphMetaDescription && { description: openGraphMetaDescription }),
                ...(openGraphImage && {
                    images: [
                        {
                            url: openGraphImage?.file?.url,
                            alt: openGraphImage?.description,
                            width: openGraphImage?.file?.details?.image?.width,
                            height: openGraphImage?.file?.details?.image?.height,
                        },
                    ],
                }),
                url:
                    canonical ??
                    getCanonicalUrl(locale === defaultLocale ? pathName : `/${locale + pathName}`),
            }}
            canonical={
                canonical ??
                getCanonicalUrl(locale === defaultLocale ? pathName : `/${locale + pathName}`)
            }
            {...(isRobotNoFollow(robotFollow) && { nofollow: true })}
            {...(isRobotNoIndex(robotIndex) && { noindex: true })}
        />
    );
};
